<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">
        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.createdAt | moment("DD.MM.YYYY hh:mm")
          }}</span>
        </template>

        <!-- Status -->
        <template #cell(isActive)="data">
            <b-badge pill :variant="data.item.isActive?'light-success':'light-danger'">
                {{data.item.isActive?'AKTİF':'PASİF'}}
            </b-badge>
        </template>

        <!-- File -->
        <template #cell(file)="data">
            <span class="font-weight-bolder mb-12">{{
            data.item.file!=null?data.item.file.url:'YOK'
          }}</span>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-dropdown class="mx-1" right text="İşlemler" variant="flat-primary">
                <b-dropdown-item variant="flat-primary" @click="videoCapture(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="ScissorsIcon" class="mr-50" />
                    <span class="align-middle">Bölümler</span>
                </b-dropdown-item>
                <b-dropdown-item variant="flat-primary" @click="videoQuestion(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="HelpCircleIcon" class="mr-50" />
                    <span class="align-middle">Sorular</span>
                </b-dropdown-item>
                <b-dropdown-item @click="editVideo(data.item)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="EditIcon" class="mr-50" />
                    <span class="align-middle">Düzenle</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteVideo(data.item.id)" v-if="data.item.originalRef=='00000000-0000-0000-0000-000000000000'">
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span class="align-middle">Sil</span>
                </b-dropdown-item>
            </b-dropdown>
        </template>

    </b-table>

    <!--Open Add Video-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addVideoPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Video Ekle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Video Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addVideoRequest.name" />
                </b-form-group>
                <b-form-group label="Video Açıklaması" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="addVideoRequest.description" rows="3" />
                </b-form-group>
                <b-form-group label="Video Url" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addVideoRequest.url" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addVideo">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Open Edit Video-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="editVideoPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Video Düzenle</h5>

                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Video Adı" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editVideoRequest.name" />
                </b-form-group>
                <b-form-group label="Video Açıklaması" label-for="name">
                    <b-form-textarea id="textarea-default" v-model="editVideoRequest.description" rows="3" />
                </b-form-group>
                <b-form-group label="Video Url" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="editVideoRequest.url" />
                </b-form-group>
                <b-form-group label="Yayınlama" label-for="from">
                    <b-form-checkbox v-model="editVideoRequest.isActive">
                        Öğrencilere Yayınlansın
                    </b-form-checkbox>
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="updateVideo">
                        Güncelle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>

    </b-sidebar>

    <!--Video Question-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="100%" shadow backdrop right v-model="openQuestionPanel">
        <template #footer>
            <div class="d-flex text-light align-items-center px-3 py-1">
                <strong class="mr-auto"></strong>
                <b-button variant="primary" class="mr-2" type="submit" @click="addQuestionPanel=true">
                    Yeni Soru Ekle
                </b-button>
            </div>
        </template>
        <question-table :videoId="selectedVideo.id" v-if="openQuestionPanel" :saveEvent="saveEvent" />
    </b-sidebar>

    <!--Video Capture-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" width="100%" shadow backdrop right v-model="openCapturePanel">
        <template #footer>
            <div class="d-flex text-light align-items-center px-3 py-1">
                <strong class="mr-auto"></strong>
                <b-button variant="primary" class="mr-2" type="submit" @click="addCapturePanel=true">
                    Yeni Bölüm Ekle
                </b-button>
            </div>
        </template>
        <capture-table :videoId="selectedVideo.id" v-if="openCapturePanel" :saveEvent="saveEvent" />
    </b-sidebar>

    <!--Add Test Question --->
    <b-modal title="Soru Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addQuestionPanel" @ok="addVideoQuestion">
        <b-form>
            <b-form-group label="Soru Resmi ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.questionUrl" />
            </b-form-group>
            <b-form-group label="Çözüm Videosu ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="addQuestionRequest.solutionUrl" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="addQuestionRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
        </b-form>
    </b-modal>

    <!--Add Capture --->
    <b-modal title="Bölüm Ekle" ok-title="Ekle" ok-only cancel-variant="outline-secondary" no-close-on-backdrop v-model="addCapturePanel" @ok="addVideoCapture">
        <b-form>
            <b-form-group label="Bölüm Adı ?" label-for="name">
                <b-form-input name="firstName" id="name" type="text" v-model="addCaptureRequest.title" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="addCaptureRequest.startTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
            <b-form-group label="Başlama Süresi ?" label-for="name">
                <cleave id="time" v-model="addCaptureRequest.endTime" class="form-control" :raw="false" :options="time" placeholder="sa:dk:sn" />
            </b-form-group>
        </b-form>
    </b-modal>
</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuestionTable from "./PageComponent/VideoQuestionTable"
import CaptureTable from "./PageComponent/VideoCapture"
import Cleave from 'vue-cleave-component'
export default {
    components: {
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        "question-table": QuestionTable,
        "capture-table": CaptureTable,
        Cleave,
        BDropdown,
        BDropdownItem
    },
    data() {
        return {
            time: {
                time: true,
                timePattern: ['h', 'm', 's'],
            },
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdAt', label: 'Kayıt Tarihi' },
                { key: 'isActive', label: 'Durum' },
                { key: 'name', label: 'Video Adı' },
                { key: 'description', label: 'Video Açıklaması' },
                { key: 'file', label: 'Video' },
                { key: 'actions', label: 'İşlemler' },
            ],
            addVideoRequest: {
                videoGroupId: 0,
                gainId: '',
                name: '',
                description: '',
                url: ''
            },
            editVideoRequest: {
                videoId: 0,
                gainId: '',
                name: '',
                description: '',
                url: '',
                isActive: true
            },
            users: [],
            gains: [],
            addVideoPanel: false,
            editVideoPanel: false,
            openQuestionPanel: false,
            selectedVideo: {},
            saveEvent: null,
            addQuestionPanel: false,
            addQuestionRequest: {
                videoId: '',
                questionFile: '',
                solutionFile: '',
                startTime: '00:00:00'
            },
            openCapturePanel: false,
            addCapturePanel: false,
            addCaptureRequest: {
                videoId: '',
                title: '',
                startTime: '00:00:00',
                endTime: '00:00:00'
            },
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addVideoPanel = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Teacher/Video/" + this.$route.params.videoGroupId);
            this.users = users.data.data;
        },
        editVideo(data) {
            //console.log(data);
            this.editVideoRequest = {
                videoId: data.id,
                name: data.name,
                description: data.description,
                url: data.file.url,
                isActive: data.isActive,
                gainId: data.gains != null ? data.gains.id : ''
            }
            this.editVideoPanel = true;
        },
        addVideo() {
            this.addVideoRequest.videoGroupId = this.$route.params.videoGroupId;
            var request = this.addVideoRequest;
            this.$http.post("Teacher/AddVideo", request).then((data) => {
                    this.getData();
                    this.addVideoPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Video Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        updateVideo() {
            var request = this.editVideoRequest;
            this.$http.put("Teacher/UpdateVideo", request).then((data) => {
                    this.getData();
                    this.editVideoPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Video Güncellendi",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        videoQuestion(video) {
            this.selectedVideo = video;
            this.openQuestionPanel = true;
        },
        videoCapture(video) {
            this.selectedVideo = video;
            this.openCapturePanel = true;
        },
        async addVideoQuestion() {
            var request = this.addQuestionRequest;
            request.videoId = this.selectedVideo.id;
            var questions = await this.$http.post("Teacher/AddVideoQuestion", request).then((data) => {
                    this.saveEvent = !this.saveEvent;
                    this.addQuestionPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Soru  Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        async addVideoCapture() {
            var request = this.addCaptureRequest;
            request.videoId = this.selectedVideo.id;
            var questions = await this.$http.post("Teacher/AddVideoCapture", request).then((data) => {
                    this.saveEvent = !this.saveEvent;
                    this.addCapturePanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Bölüm  Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteVideo(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: confirm => {
                    if (confirm) {
                        this.$http.delete("Teacher/DeleteVideo/" + data).then(() => {
                                this.getData();
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'İşlem',
                                        icon: 'BellIcon',
                                        text: "İşlem Başarılı Video Silindi",
                                        variant: 'success'
                                    },
                                });
                            })
                            .catch((error) => {
                                //console.log(error.response.data)
                                this.$toast({
                                    component: ToastificationContent,
                                    props: {
                                        title: 'Hata',
                                        icon: 'BellIcon',
                                        text: error.response.data.error.message,
                                        variant: 'danger'
                                    },
                                });
                            });
                    }
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
